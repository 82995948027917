<template>
    <div>
        <section class="encabezado encuesta-medicina row-start">

            <h2>Encuesta</h2>

        </section>
        <section class="contenedor-encuesta row-between" id="encuesta">

            <h2>Dirigida a los alumni Ufv de Biomedicina</h2>

            <legend>Datos personales</legend>


            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="Nombre" v-model="data.name"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="surnames" placeholder="Primer Apellido"
                                v-model="data.surname_1" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="surnames" placeholder="Segundo Apellido"
                                v-model="data.surname_2" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="dni" placeholder="DNI" :class="classes"
                                v-model="data.dni">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="email" placeholder="Correo electrónico"
                                :class="classes" v-model="data.email">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:9" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="mobile" placeholder="Teléfono movil"
                                :class="classes" v-model="data.mobile">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="min:9" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="mobile" placeholder="Teléfono"
                                :class="classes" v-model="data.phone">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <legend>Dirección postal</legend>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="address" placeholder="Dirección completa"
                                :class="classes" v-model="data.address">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="cp" placeholder="Código postal" :class="classes"
                                v-model="data.cp">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="city" placeholder="Población" :class="classes"
                                v-model="data.city">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="province" placeholder="Provincia"
                                :class="classes" v-model="data.province">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <legend>En qué situación te encuentras:</legend>


                    <div class="contenedor-input -check pb0">
                        <input type="checkbox" id="studying_master" v-model="data.studying_master" name="studying_master">
                        <label for="studying_master">Estoy estudiando un máster</label>
                    </div>

                    <div class="contenedor-input" v-if="data.studying_master">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="¿Cuál, en qué área temática, en qué universidad, ciudad, país?*"
                                v-model="data.info_master" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -check pb0">
                        <input type="checkbox" id="studying_degree" v-model="data.studying_degree" name="studying_degree">
                        <label for="studying_degree">Estoy estudiando otro grado, curso o postgrado</label>
                    </div>

                    <div class="contenedor-input" v-if="data.studying_degree">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="¿Cuál/es?*"
                                v-model="data.info_degree" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -check pb0">
                        <input type="checkbox" id="studying_doctorate" v-model="data.studying_doctorate" name="studying_doctorate">
                        <label for="studying_doctorate">Estoy realizando un doctorado</label>
                    </div>

                    <div class="contenedor-input" v-if="data.studying_doctorate">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="¿Cuál y asociado a qué universidad, en qué centro/institución realizas tu tesis doctoral, en qué área temática?*"
                                v-model="data.info_doctorate" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -check pb0">
                        <input type="checkbox" id="internship" v-model="data.internship" name="internship">
                        <label for="internship">Estoy realizando prácticas</label>
                    </div>

                    <div class="contenedor-input" v-if="data.internship">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="¿En qué centro/empresa, departamento, ciudad y país. ¿En qué área temática?*"
                                v-model="data.info_internship" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -check pb0">
                        <input type="checkbox" id="working" v-model="data.working" name="working">
                        <label for="working">Estoy trabajando</label>
                    </div>

                    <div class="contenedor-input" v-if="data.working">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="¿En qué centro/empresa/institución, departamento, ciudad y país? ¿En qué área temática?*"
                                v-model="data.info_working" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -check pb0">
                        <input type="checkbox" id="connect_experimental" v-model="data.connect_experimental" name="connect_experimental">
                        <label for="connect_experimental">Me gustaría conectar mi institución/centro/empresa con la Facultad de CC. Experimentales de la UFV para acoger alumnos de prácticas institucionales</label>
                    </div>

                    <div class="contenedor-input" v-if="data.connect_experimental">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="¿Qué te gustaría que te proporcionase la red de alumni de Biomedicina?*"
                                v-model="data.biomed_provides" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input -check mt20">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="checkbox" id="id" :class="classes" v-model="data.politica" name="politica">
                            <label for="id">He leído y acepto la <a href="">Política de privacidad</a></label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <a @click="validate" class="boton-azul">Enviar</a>
                </form>

            </ValidationObserver>


            <div class="form-popup-encuesta" :show="!!error">
                <div class="popup" v-if="error">
                    <h2>{{errores[error].titulo}}</h2>
                    <p>{{errores[error].cuerpo}}</p>
                    <a @click="reload" class="boton-azul">Aceptar</a>
                </div>
            </div>

        </section>
    </div>
</template>


<script>

    import axios from 'axios';

    export default {
        name: 'encuesta-medicina',
        data: () => ({
            data: {
                'name': '',
                'surname_1': '',
                'surname_2': '',
                'dni': '',
                'phone': '',
                'mobile': '',
                'email': '',
                'address': '',
                'cp': '',
                'city': '',
                'province': '',

                'studying_master': false,
                'info_master': '',

                'studying_degree': false,
                'info_degree': '',

                'studying_doctorate': false,
                'info_doctorate': '',

                'internship': false,
                'info_internship': '',

                'working': false,
                'info_working': '',

                'connect_experimental': false,

                'biomed_provides': '',
                politica: false
            },
            errores: {
                'send': {
                    titulo: '¡Muchas gracias!',
                    cuerpo: "Hemos recibido tus datos"
                }
            },
            error: null,
            clicked: false
        }),
        methods: {
            reset() {
                this.errors.clear();
                this.clicked = false;
                this.data = {
                    'name': '',
                'surname_1': '',
                'surname_2': '',
                'dni': '',
                'phone': '',
                'mobile': '',
                'email': '',
                'address': '',
                'cp': '',
                'city': '',
                'province': '',

                'studying_master': false,
                'info_master': '',

                'studying_degree': false,
                'info_degree': '',

                'studying_doctorate': false,
                'info_doctorate': '',

                'internship': false,
                'info_internship': '',

                'working': false,
                'info_working': '',

                'connect_experimental': false,

                'biomed_provides': '',
                politica: false
                }
            },
            reload() {
                location.reload();
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        axios.post('https://cuestionarios.alumni-ufv.es/api/contacto/biomed', this.data).then(response => {
                            if (response.data.status) {
                                this.error = 'send';
                                this.reset();
                            }
                        })

                    }
                });
            },
        }
    }
</script>