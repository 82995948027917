<template>
    <div>
        <section class="encabezado encuesta-medicina row-start">

            <h2>Encuesta</h2>

        </section>
        <section class="contenedor-encuesta row-between" id="encuesta">

            <h2>Dirigida a los alumni Ufv de Fisioterapia</h2>

            <legend>Datos personales</legend>


            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="Nombre" v-model="data.name"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="surname_1" placeholder="Primer Apellido"
                                v-model="data.surname_1" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="surname_2" placeholder="Segundo Apellido"
                                v-model="data.surname_2" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="dni" placeholder="DNI" :class="classes"
                                v-model="data.dni">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="email" placeholder="Correo electrónico"
                                :class="classes" v-model="data.email">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    
                    <div class="contenedor-input half">
                        <ValidationProvider rules="min:9" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="phone" placeholder="Teléfono"
                                v-model="data.phone" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="min:9" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="mobile" placeholder="Teléfono móvil"
                                v-model="data.mobile" :classes="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="address" placeholder="Dirección completa"
                                :class="classes" v-model="data.address">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>


                    <legend>En qué situación te encuentras</legend>


                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select autocomplete="off" name="areas_specialise" :class="classes"
                                v-model="data.areas_specialise">
                                <option value="">¿En qué área estas especializado?</option>
                                <option value="0">Fisioterapia general.</option>
                                <option value="1">Fisioterapia Traumatológica/Deportiva</option>
                                <option value="2">Fisioterapia Neurológica</option>
                                <option value="3">Fisioterapia Pediátrica</option>
                                <option value="4">Fisioterapia Oncológica</option>
                                <option value="5">Otros</option>
                            </select>

                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input row-start fww">
                        <p>¿Estás en activo?</p>
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="radio" :value="true" name="active" id="si" :class="classes"
                                v-model="data.active" v-validate="'required'">
                            <label for="si">Sí</label>
                            <input type="radio" :value="false" name="active" id="no" :class="classes"
                                v-model="data.active" v-validate="'required'">
                            <label for="no">No</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <template v-if="data.active">


                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <select autocomplete="off" name="currently_work" :class="classes"
                                    v-model="data.currently_work">
                                    <option :value="null">¿Dónde trabajas actualmente?</option>
                                    <option value="0">Clínica propia</option>
                                    <option value="1">Clínica ajena</option>
                                    <option value="2">Hospital</option>
                                    <option value="3">Centro deportivo</option>
                                    <option value="4">Otros</option>
                                </select>
    
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
    
                        </div>


                        <div class="contenedor-input" v-if="data.currently_work == 0">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="interested_trainees" placeholder="¿Estás interesado en contar con alumnos de prácticas de la UFV?"
                                    :class="classes"  v-model="data.interested_trainees">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input" v-if="data.currently_work == 2">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="hospital_name" placeholder="Nombre del Hospital"
                                    :class="classes"  v-model="data.hospital_name">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input" v-if="data.currently_work == 3">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="sport_centre" placeholder="¿Cuál?"
                                    :class="classes"  v-model="data.sport_centre">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input" v-if="data.currently_work == 4">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="where" placeholder="Por favor, indica dónde"
                                    :class="classes"  v-model="data.where">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="city" placeholder="¿Ciudad en la que trabajas?"
                                    :class="classes"  v-model="data.city">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <select autocomplete="off" name="interested_participating" :class="classes"
                                    v-model="data.interested_participating">
                                    <option value="">¿Estás interesado en participar en seminarios, congresos, formaciones en la
                                        UFV?
                                    </option>
                                    <option value="0">Sí, como ponente.</option>
                                    <option value="1">Sí, como oyente.</option>
                                    <option value="2">Sí, como ponente u oyente..</option>
                                    <option value="3">No. </option>
                                </select>
    
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
    
                        </div>


                        <div class="contenedor-input">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="alumni_help" placeholder="¿Piensas que en Alumni UFV te podríamos ayudar de alguna manera?"
                                    :class="classes"  v-model="data.alumni_help">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                    </template>


                    <div class="contenedor-input" v-if="data.active == false">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="alumni_help" placeholder="¿Piensas que en Alumni UFV te podríamos ayudar de alguna manera?"
                                :class="classes"  v-model="data.alumni_help">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="contenedor-input -check">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="checkbox" id="id" :class="classes" v-model="data.politica" name="politica">
                            <label for="id">He leído y acepto la <a href="">Política de privacidad</a></label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <a @click="validate" class="boton-azul">Enviar</a>
                </form>

            </ValidationObserver>


            <div class="form-popup-encuesta" :show="!!error">
                <div class="popup" v-if="error">
                    <h2>{{errores[error].titulo}}</h2>
                    <p>{{errores[error].cuerpo}}</p>
                    <a @click="reload" class="boton-azul">Aceptar</a>
                </div>
            </div>

        </section>
    </div>
</template>


<script>

    import axios from 'axios';

    export default {
        name: 'encuesta-farmacia',
        data: () => ({
            data: {
                'name': '',
                'surname_1': '',
                'surname_2': '',
                'dni': '',
                'phone': '',
                'mobile': '',
                'email': '',
                'address': '',


                'areas_specialise': '',
                'active': null,
                'working_help': '',
                'currently_work': null,
                'interested_trainees': '',
                'hospital_name': '',
                'sport_centre': '',
                'where': '',
                'city': '',
                'interested_participating': '',
                'alumni_help': '',
                politica: false
            },
            errores: {
                'send': {
                    titulo: '¡Muchas gracias!',
                    cuerpo: "Hemos recibido tus datos"
                }
            },
            error: null,
            clicked: false
        }),
        methods: {
            reset() {
                this.errors.clear();
                this.clicked = false;
                this.data = {
                    'name': '',
                    'surname_1': '',
                    'surname_2': '',
                    'dni': '',
                    'phone': '',
                    'mobile': '',
                    'email': '',
                    'address': '',


                    'areas_specialise': '',
                    'active': null,
                    'working_help': '',
                    'currently_work': null,
                    'interested_trainees': '',
                    'hospital_name': '',
                    'sport_centre': '',
                    'where': '',
                    'city': '',
                    'interested_participating': '',
                    'alumni_help': '',
                    politica: false
                }
            },
            reload() {
                location.reload();
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        axios.post('https://cuestionarios.alumni-ufv.es/api/contacto/physio', this.data).then(response => {
                            if (response.data.status) {
                                this.error = 'send';
                                this.reset();
                            }
                        })

                    }
                });
            },
        }
    }
</script>


<style lang="scss" scoped>
.contenedor-input select{
    width: 100%;
}
</style>